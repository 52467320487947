import React from 'react';
import './index.css';
import App from './page/app/App';
import LoginPage from './page/login/Login';
import {AuthProvider, useAuth} from './AuthContext';
import {createRoot} from 'react-dom/client';
import axios from 'axios';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

// src/index.tsx
const Root = () => {
    const {user} = useAuth();

    // Set the default header as userId
    // in full application this would be a JWT token
    if (user) {
        axios.defaults.headers.common['userId'] = user.id;
    }

    return user ? <App/> : <LoginPage/>;
};

const element = document.getElementById('root');
const root = createRoot(element!);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <Root/>
        </AuthProvider>
    </BrowserRouter>
);
