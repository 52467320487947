import React, {ReactNode} from 'react';
import {BrowserRouter, useNavigate} from 'react-router-dom';

interface User {
    id: number;
    createdAt: string;
    username: string;
    email: string;
    emailNotificationsEnabled: boolean;
    notificationEmailAddress: string;
}

interface AuthContextType {
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    logout: () => void;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = React.useState<User | null>(null);

    const logout = () => {
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{user, setUser, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

const AuthNavigation = () => {
    const navigate = useNavigate();
    const {logout} = useAuth();

    React.useEffect(() => {
        if (!logout) {
            navigate('/login');
        }
    }, [logout, navigate]);

    return null;
};

export const useAuth = (): AuthContextType => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
