import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {useAuth} from '../../AuthContext';
import {API_BASE_URL} from '../../apiConfig';

const Settings: React.FC = () => {
    const {user, setUser} = useAuth();
    const [username, setUsername] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.notificationEmailAddress || '');
    const [notificationsEnabled, setNotificationsEnabled] = useState(user?.emailNotificationsEnabled || false);

    useEffect(() => {
        setUsername(user?.username || '');
        setEmail(user?.notificationEmailAddress || '');
        setNotificationsEnabled(user?.emailNotificationsEnabled || false);
    }, [user]);

    const handleUpdate = () => {
        if (user?.username !== username) {
            axios.put(`${API_BASE_URL}/api/user/username?username=${username}`)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => console.error('Error updating username:', error));
        }

        if (user?.email !== email) {
            axios.put(`${API_BASE_URL}/api/user/notification-email-address?email=${email}`)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => console.error('Error updating email:', error));
        }

        if (user?.emailNotificationsEnabled !== notificationsEnabled) {
            axios.put(`${API_BASE_URL}/api/user/email-notifications?enabled=${notificationsEnabled}`)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => console.error('Error updating email notifications:', error));
        }
    };


    return (
        <Container style={{width: '60%', margin: 'auto'}}>
            <Row className="justify-content-md-center">
                <Col md={12}>
                    <h2 style={{textAlign: 'center', margin: '50px 0'}}>Settings</h2>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Username: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <input type="text" value={username} onChange={e => setUsername(e.target.value)}
                                   style={{width: '200px'}}/>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Email: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)}
                                   style={{width: '200px'}}/>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Email Notifications Enabled: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <input type="checkbox" checked={notificationsEnabled}
                                   onChange={e => setNotificationsEnabled(e.target.checked)}/>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center align-items-center" style={{marginBottom: '20px'}}>
                        <Button style={{
                            width: '200px',
                            height: '50px',
                            marginTop: '50px',
                        }} onClick={handleUpdate}>Update</Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Settings;
