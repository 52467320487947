import React, {useState} from 'react';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Alerts from '../alert/Alerts';
import LoginPage from '../login/Login';
import Settings from "../settings/Settings";
import {useAuth} from '../../AuthContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faUser, faCog} from '@fortawesome/free-solid-svg-icons';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import './App.css';

const App: React.FC = () => {
    const {user, logout} = useAuth();
    const [selectedNavItem, setSelectedNavItem] = useState("/dashboard");

    const handleNavItemClick = (path: string) => {
        setSelectedNavItem(path);
    };

    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand className="logo">Trade Pal</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="nav-items">
                        <Link className={selectedNavItem === "/dashboard" ? "nav-link active" : "nav-link"}
                              to="/dashboard"
                              onClick={() => handleNavItemClick("/dashboard")}>Dashboard</Link>
                        <Link className={selectedNavItem === "/alerts" ? "nav-link active" : "nav-link"}
                              to="/alerts"
                              onClick={() => handleNavItemClick("/alerts")}>Alerts</Link>
                    </Nav>
                    <Nav>
                        <NavDropdown title={user?.username} id="basic-nav-dropdown"
                                     className="dropdown-menu-align-right">
                            <Link className="dropdown-item" to="/settings"
                                  onClick={() => handleNavItemClick("/settings")}>
                                <FontAwesomeIcon icon={faCog}/> Settings
                            </Link>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={logout}>
                                <FontAwesomeIcon icon={faSignOutAlt}/> Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Routes>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/alerts" element={<Alerts/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/settings" element={<Settings/>}/>
            </Routes>
        </div>
    );
};

export default App;
