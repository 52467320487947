import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {useAuth} from '../../AuthContext';
import {Container, Row, Col, Table, Button} from 'react-bootstrap';
import {API_BASE_URL} from '../../apiConfig';

interface Alert {
    id: number;
    userId: number;
    sendEmailNotification: boolean;
    conditionsMet: boolean;
    createdAt: string;
    assetSymbol: string;
    conditionType: number;
    conditionValue: number;
}

const AlertsOverview = () => {
    const {user} = useAuth();
    const [alerts, setAlerts] = useState<Alert[]>([]);

    // we do data polling as its simple, in a real world scenario we would use websocket to trigger updates
    useEffect(() => {
        if (user) {
            fetchAlerts();
            const interval = setInterval(() => {
                fetchAlerts();
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [user]);

    const fetchAlerts = () => {
        axios.get(`${API_BASE_URL}/api/dashboard`)
            .then(response => setAlerts(response.data))
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleDelete = (id: number) => {
        axios.delete(`${API_BASE_URL}/api/alert?id=${id}`)
            .then(() => fetchAlerts())
            .catch(error => console.error('Error deleting alert:', error));
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="10">
                    <h2 className="alerts-overview">Alerts Overview</h2>
                    <div className="table-container">
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th className="table-header">Alert Conditions</th>
                                <th className="table-header">Conditions Met</th>
                                <th className="table-header">Email Notification</th>
                                <th className="table-header">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {alerts.map(alert => (
                                <tr key={alert.id}>
                                    <td className="table-cell">{alert.assetSymbol} price {alert.conditionType ? "is below" : "is above"} {alert.conditionValue}</td>
                                    <td className="table-cell"><FontAwesomeIcon
                                        icon={alert.conditionsMet ? faCheck : faTimes}/></td>
                                    <td className="table-cell"><FontAwesomeIcon
                                        icon={alert.sendEmailNotification ? faCheck : faTimes}/></td>
                                    <td className="table-cell">
                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDelete(alert.id)}
                                                         style={{cursor: 'pointer'}}/>
                                    </td>
                                </tr>)
                            )}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AlertsOverview;
