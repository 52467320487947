import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import {API_BASE_URL} from '../../apiConfig';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const {setUser} = useAuth();
    const navigate = useNavigate();

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!validateEmail(email)) {
            setError('Invalid email format');
            return;
        }

        const response = await axios.post(`${API_BASE_URL}/api/user?email=${encodeURIComponent(email)}`);
        setUser(response.data);
        navigate('/dashboard');
    }

    return (
        <div className="container d-flex align-items-center min-vh-100">
            <div className="row justify-content-center w-100">
                <div className="col-12 col-md-8 col-lg-6">
                    <h3 className="text-center mb-4">Register/Login</h3>
                    <form onSubmit={handleSubmit} className="form-group">
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control mb-3"
                        />
                        <button type="submit" className="btn btn-primary mt-3 w-100">Submit</button>
                    </form>
                    {error && <p className="text-danger mt-3">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
