import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {Container, Row, Col, Button} from 'react-bootstrap';
import {API_BASE_URL} from '../../apiConfig';

const Alerts: React.FC = () => {
    const [asset, setAsset] = useState('');
    const [condition, setCondition] = useState(0);
    const [price, setPrice] = useState(0);
    const [emailNotification, setEmailNotification] = useState(false);
    const [assets, setAssets] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${API_BASE_URL}/api/alert/assets`)
            .then(response => response.json())
            .then(data => {
                setAssets(data);
                if (data.length > 0) {
                    setAsset(data[0]);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const handleButtonClick = () => {
        const alertData = {
            userId: 1,
            sendEmailNotification: emailNotification,
            assetSymbol: asset,
            conditionType: condition,
            price: price
        };

        axios.post(`${API_BASE_URL}/api/alert`, alertData)
            .then(response => navigate('/dashboard'))
            .catch(error => console.error('Error:', error));
    };

    return (
        <Container style={{width: '60%', margin: 'auto'}}>
            <Row className="justify-content-md-center">
                <Col md={12}>
                    <h2 style={{textAlign: 'center', margin: '50px 0'}}>Add Alert</h2>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Asset: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <select value={asset} onChange={e => setAsset(e.target.value)} style={{width: '120px'}}>
                                {assets.map((asset, index) => (
                                    <option key={index} value={asset}>{asset}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Condition: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <select value={condition} onChange={e => setCondition(parseInt(e.target.value))}
                                    style={{width: '120px'}}>
                                <option value="0">is above</option>
                                <option value="1">is below</option>
                            </select>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Price: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <input type="number" value={price} onChange={e => setPrice(e.target.valueAsNumber)}
                                   style={{width: '120px'}}/>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Email Notification: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <input type="checkbox" checked={emailNotification}
                                   onChange={e => setEmailNotification(e.target.checked)}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} style={{textAlign: 'left'}}>
                            <label>Result Condition: </label>
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            <span>{`${asset} price ${condition === 0 ? 'is above' : 'is below'} ${price}`}</span>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center align-items-center" style={{marginBottom: '20px'}}>
                        <Button style={{
                            width: '200px',
                            height: '50px',
                            marginTop: '50px',
                        }} onClick={handleButtonClick}>Submit</Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Alerts;
